import http, { getToken } from '@/http-common'
import { ConditionSet } from '@/model/acceptRide/ConditionSet'
import { Config, getConfig } from '@/config'

const config: Config = getConfig()
const path = '/condition-sets'

export async function getConditionSet(businessId: string): Promise<ConditionSet | undefined> {
  try {
    const res = await http.get(path + '/' + businessId, {
      headers: {
        Authorization: await getToken(),
      },
    })
    return res.data
  } catch (err) {
    console.log('Error loading Condition Set')
  }
  return undefined
}

export async function createConditionSet(conditionSet: ConditionSet): Promise<any> {
  return await http
    .post(path, conditionSet, {
      method: 'POST',
      headers: {
        Authorization: await getToken(),
      },
    })
    .catch(function (error) {
      return error.response
    })
}

export async function updateConditionSet(conditionSet: ConditionSet): Promise<any> {
  return await http
    .put(path, conditionSet, {
      method: 'POST',
      headers: {
        Authorization: await getToken(),
      },
    })
    .catch(function (error) {
      return error.response
    })
}

export async function deleteConditionSet(businessId: string): Promise<any> {
  return await http
    .delete(path + '/' + businessId, {
      method: 'POST',
      headers: {
        Authorization: await getToken(),
      },
    })
    .catch(function (error) {
      return error.response
    })
}
