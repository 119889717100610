
import { Options, Vue } from 'vue-class-component'
import NavbarDefault from '@/components/NavbarDefault.vue'
import NotificationComponent from '@/components/NotificationComponent.vue'

@Options({
  data() {
    return {
      isLoading: this.$auth0.isLoading,
    }
  },
  components: {
    NavbarDefault,
    NotificationComponent,
  },
})
export default class App extends Vue {}
