import { ConditionSet } from '@/model/acceptRide/ConditionSet'
import http, { getToken } from '@/http-common'
import { Config, getConfig } from '@/config'
import { Partner } from '@/model/partner/Partner'

const config: Config = getConfig()
const path = '/company/' + config.companyId + '/partner'

export async function getAllPartners(): Promise<Array<Partner>> {
  try {
    const res = await http.get(path, {
      headers: {
        Authorization: await getToken(),
      },
    })
    return res.data
  } catch (err) {
    console.log('Error loading Partners')
  }
  return []
}

export async function getConditionSets(partner: Partner): Promise<Array<ConditionSet>> {
  try {
    const res = await http.get(path + '/' + partner.businessId + '/condition-set', {
      headers: {
        Authorization: await getToken(),
      },
    })
    return res.data
  } catch (err) {
    console.log('Error loading Condition Sets')
  }
  return []
}
