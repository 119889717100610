import { InjectionKey } from 'vue'
import { createStore, Store, useStore as baseUseStore } from 'vuex'
import { Driver } from '@/services/DriverService'
import { ConditionSet } from '@/model/acceptRide/ConditionSet'
import { Notification } from '@/model/Notification'
import { Partner } from '@/model/partner/Partner'

// define your typings for the store state
export interface State {
  driver: Driver | undefined
  conditionSet: ConditionSet | undefined
  partner: Partner | undefined
  partners: Partner[]
  ride: any
  notifications: Notification[]
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
  state: {
    driver: undefined,
    conditionSet: undefined,
    partner: undefined,
    partners: [],
    ride: null,
    notifications: [],
  },
})

// define your own `useStore` composition function
export function useStore() {
  return baseUseStore(key)
}
