import { PARTNER_TYPE } from '@/model/partner/PartnerType'

export class Partner {
  businessId: string
  name: string
  partnerType: PARTNER_TYPE
  companyId: string | undefined

  constructor(businessId: string, name: string, partnerType: PARTNER_TYPE, companyId: string | undefined) {
    this.businessId = businessId
    this.name = name
    this.partnerType = partnerType
    this.companyId = companyId
  }
}

export function getNewPartner(): Partner {
  return new Partner('', 'SELECT PARTNER', PARTNER_TYPE.BLACKLANE, undefined)
}
